import React, { Component } from "react";
import { Link } from "gatsby";
import Layout from "./layout";
import "../css/style.css"

class ProgramStrutcurePG extends Component {
  


render(){
  return(

                        <div>
                            <div class="campus-learning-inner">
                                <div class="text-box">
                                    <h3>{this.props.Stage1Title}</h3>
                                    <p>{this.props.Stage1Details}</p>
                                
                                </div>
                                <div class="campus-learning-box">
                                {this.props.subjectInfo}    
                                </div>
                            </div>
                            <div class="campus-learning-inner">
                                <div class="text-box">
                                    <h3>{this.props.Stage2Title}</h3>
                                    <p>{this.props.Stage2Details}</p>
                                </div> 
                            </div>
                        </div>
  )
}

}

export default ProgramStrutcurePG;