import React, { Component } from "react";
import { Link } from "gatsby";
import Layout from "./layout";
import "../css/style.css"

class Coursedetail extends Component {

	render() {
		return (
			<div class="row justify-content-center">
				<div class="col-12 col-xl-6 col-lg-6 col-md-10 md-margin-50px-bottom">
					<div class="col-12 p-0 margin-3-rem-bottom wow animate__slideInLeft" >
						<h2>{this.props.AdmissionsInformationTitle}</h2>
					</div>
					<div class="col-12 p-0">
						
					{this.props.QualificationTitle ? (
						<div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
							<div class="process-step-item">
								<div class="process-step-icon-wrap">
									<div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
									<span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
								</div>
								<div class="process-content last-paragraph-no-margin">
									<span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{ this.props.QualificationTitle }</span>
									<div class="w-80 xs-w-100 with-bullet" dangerouslySetInnerHTML={{ __html: this.props.QualificationInfo }} />
								</div>
							</div>
						</div>
					) : ("")}
					{this.props.EnglishRequirmentsTitle ? (
						<div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
							<div class="process-step-item">
								<div class="process-step-icon-wrap">
									<div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
									<span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
								</div>
								<div class="process-content last-paragraph-no-margin">
									<span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{ this.props.EnglishRequirmentsTitle }</span>
									<div class="w-80 xs-w-100 with-bullet" dangerouslySetInnerHTML={{ __html: this.props.EnglishRequirments }} />
								</div>
							</div>
						</div>
					) : ("")}

					{this.props.OtherRequirmentsTitle ? (
						<div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
							<div class="process-step-item">
								<div class="process-step-icon-wrap">
									<div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
									<span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
								</div>
								<div class="process-content last-paragraph-no-margin">
									<span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{ this.props.OtherRequirmentsTitle }</span>
									<div className="w-80 xs-w-100 with-bullet" dangerouslySetInnerHTML={{ __html: this.props.OtherRequirments}} />
								</div>
							</div>
						</div>

					) : ("")}

					{this.props.OnlineFeesTitle ? (
						<div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
							<div class="process-step-item">
								<div class="process-step-icon-wrap">
									<div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
									<span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
								</div>
								<div class="process-content last-paragraph-no-margin">
									<span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{ this.props.OnlineFeesTitle }</span>
									<div class="w-80 xs-w-100">
										<span class="d-block">{this.props.OnlineFeesLabel} {this.props.OnlineFees}</span>
										<span class="d-block">{this.props.OfflineFeesLabel} {this.props.CampusFees}</span>
									</div>
								</div>
							</div>
						</div>
					) : ("")}

{this.props.ScholarshipLabel ? (
						<div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
							<div class="process-step-item">
								<div class="process-step-icon-wrap">
									<div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
									<span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
								</div>
								<div class="process-content last-paragraph-no-margin">
									<span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{ this.props.ScholarshipLabel }</span>
									
									<div class="w-80 xs-w-100 with-bullet" dangerouslySetInnerHTML={{ __html: this.props.ScholarshipText}} />
									
								</div>
							</div>
						</div>
					) : ("") }

                 {this.props.SavingsLabel ? (
						<div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
							<div class="process-step-item">
								<div class="process-step-icon-wrap">
									<div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
									<span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
								</div>
								<div class="process-content last-paragraph-no-margin">
									<span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{ this.props.SavingsLabel }</span>
									
									<div class="w-80 xs-w-100 with-bullet" dangerouslySetInnerHTML={{ __html: this.props.SavingsText}} />
									
								</div>
							</div>
						</div>
					) : ("")}


       

					</div>


				</div>


				<div class="animate__slideInRight animated bg-cover br-15 col-12 col-lg-6 margin15 mt-5 mt-lg-0  mx-0 p-0 position-relative wow" data-wow-delay="0.1s" style={{ backgroundImage: "url(" + this.props.AdmissionsInformationImage + ")" }} >
					<img src={this.props.AdmissionsInformationImage} alt="Admission Information" data-no-retina="" class="br-15 margin15 opacity-0 show-on-mobile" />
					<div class="opacity-very-light bg-medium-slate-blue br-15 "></div>
				</div>
			</div>
		)
	}

}

export default Coursedetail;